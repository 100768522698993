import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddBlog = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImageUrl] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [blogPosts, setBlogPosts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogPosts = async () => {
            refetchBlogPosts();
        };

        fetchBlogPosts();
    }, []);

    const refetchBlogPosts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/blogs`);
            if (!response.ok) {
                throw new Error('Failed to fetch blog posts');
            }
            const data = await response.json();
            setBlogPosts(data);
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleLogin = () => {
        if (
            username === process.env.REACT_APP_ADMIN_USERNAME &&
            password === process.env.REACT_APP_ADMIN_PASSWORD
        ) {
            setIsAuthenticated(true);
        } else {
            alert('Incorrect username or password');
        }
    };

    const generateSlug = (title) => {
        return title
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const slug = generateSlug(title);
        const newPost = { title, slug, content, image };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/blogs`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newPost),
        });

        setIsSubmitting(false);

        if (response.ok) {
            alert('Blog post added successfully');
            refetchBlogPosts();
        } else {
            alert('Failed to add blog post');
        }
    };

    const handleDelete = async (slug) => {
        if (window.confirm('Are you sure you want to delete this blog post?')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/blogs/${slug}`, {
                    method: 'DELETE',
                });
                if (response.ok) {
                    alert('Blog post deleted successfully');
                    setBlogPosts(blogPosts.filter(post => post.slug !== slug));
                } else {
                    alert('Failed to delete blog post');
                }
            } catch (err) {
                console.error('Failed to delete blog post:', err.message);
            }
        }
    };

    return (
        <>
            <NavBar />
            <div className="container mx-auto py-12 px-4 md:px-0">
                <br /><br /><br /><br />
                {!isAuthenticated ? (
                    <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg">
                        <h2 className="text-2xl font-bold mb-4 text-center">Admin Login</h2>
                        <div className="mb-4">
                            <label className="block text-gray-700">Username</label>
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded-lg"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Password</label>
                            <input
                                type="password"
                                className="w-full p-2 border border-gray-300 rounded-lg"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button
                            onClick={handleLogin}
                            className="w-full bg-green-600 text-white p-2 rounded-lg"
                        >
                            Login
                        </button>
                    </div>
                ) : (
                    <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
                        <h2 className="text-2xl font-bold mb-4 text-center">Add New Blog Post</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700">Title</label>
                                <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700">Content</label>
                                <ReactQuill 
                                    value={content} 
                                    onChange={setContent} 
                                    className="bg-white p-2 border border-gray-300 rounded-lg" 
                                    theme="snow" 
                                    modules={{
                                        toolbar: [
                                            [{ 'header': [1, 2, false] }],
                                            ['bold', 'italic', 'underline'],
                                            ['link', 'image'],
                                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                        ],
                                    }}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700">Image URL</label>
                                <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 rounded-lg"
                                    value={image}
                                    onChange={(e) => setImageUrl(e.target.value)}
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-green-600 text-white p-2 rounded-lg"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Add Blog Post'}
                            </button>
                        </form>
                        
                        <h2 className="text-2xl font-bold mt-8 mb-4 text-center">Existing Blog Posts</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {blogPosts.map((post) => (
                                <div key={post.slug} className="bg-white rounded-lg shadow-md p-6">
                                    <img src={post.image} alt={post.title} className="w-full h-48 object-cover rounded-t-lg mb-4" />
                                    <h2 className="text-2xl font-bold mb-2">
                                        <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                                    </h2>
                                    <button
                                        onClick={() => handleDelete(post.slug)}
                                        className="text-red-500 hover:underline mt-2"
                                    >
                                        Delete
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AddBlog;
