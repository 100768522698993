import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import loader from '../images/loader.gif';

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/blogs`);
                if (!response.ok) {
                    throw new Error('Failed to fetch blog posts');
                }
                const data = await response.json();

                // Convert image data from binary to Base64
                const processedData = data.map(post => {
                    if (post.image && post.image.data && post.image.contentType) {
                        // const imageBase64 = Buffer.from(post.image.data).toString('base64');
                        // const imageSrc = `data:${post.image.contentType};base64,${imageBase64}`;

                        const base64Image = `data:${post.image.contentType};base64,${btoa(
                            new Uint8Array(post.image.data.data)
                                .reduce((data, byte) => data + String.fromCharCode(byte), '')
                        )}`;
                        console.log(base64Image);
                        return { ...post, image: base64Image };
                    }
                    return post;
                });

                setBlogPosts(processedData);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchBlogPosts();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={loader} alt="Loading..." />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <NavBar />
            <div className="container mx-auto py-12">
                <div className="text-center mb-12" data-aos="fade-up">
                    <br/><br/>
                    <h2 className="text-4xl font-bold text-green-700 uppercase">Blog</h2>
                    <div className="flex justify-center mt-2">
                        <div className="w-24 border-b-4 border-green-700"></div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {blogPosts.map((post) => (
                        <div key={post.slug} className="bg-white rounded-lg shadow-md p-6">
                            <img src={post.image} alt={post.title} className="w-full h-48 object-cover rounded-t-lg mb-4" />
                            <h2 className="text-2xl font-bold mb-2">
                                <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                            </h2>
                            {/* <p className="text-gray-700 mb-4">
                            <div dangerouslySetInnerHTML={{ __html: post.content.slice(0, 100) }} />...
                            </p> */}
                            <Link to={`/blog/${post.slug}`} className="text-blue-500 hover:underline">
                                Read More
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Blog;
