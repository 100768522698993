import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import loader from '../images/loader.gif';

const BlogPost = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlogPost = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/api/blogs/${slug}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch blog post');
                }
                const data = await response.json();

                // Convert image data from binary to Base64
                if (data.image && data.image.data && data.image.contentType) {
                    const base64Image = `data:${data.image.contentType};base64,${btoa(
                        new Uint8Array(data.image.data.data)
                            .reduce((data, byte) => data + String.fromCharCode(byte), '')
                    )}`;
                    data.image = base64Image;
                }

                setPost(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchBlogPost();
    }, [slug]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={loader} alt="Loading..." />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!post) {
        return <h1 className="text-center text-3xl mt-20">Post not found</h1>;
    }

    return (
        <>
            <NavBar />
            <br /><br />
            <div className="container mx-auto py-12 px-4 md:px-0">
                <div className="max-w-4xl mx-auto">
                    <div className="mb-8">
                        <img src={post.image} alt={post.title} className="w-full h-64 object-cover rounded-lg shadow-lg" />
                    </div>
                    <h1 className="text-4xl font-bold mb-8 text-green-700">{post.title}</h1>
                    <div className="prose max-w-full text-gray-700" dangerouslySetInnerHTML={{ __html: post.content }} />
                </div>
            </div>
        </>
    );
};

export default BlogPost;
