import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-green-600" smooth to="/about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-green-600" smooth to="/#services">
                Activities
            </HashLink>
            <HashLink to="/blog" className="px-4 font-extrabold text-gray-500 hover:text-green-600">
                Blog
            </HashLink>
            {/* <HashLink className="px-4 font-extrabold text-gray-500 hover:text-green-600" to="/">
                Portfolio
            </HashLink> */}
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-green-600" to="/contact#contact">
                Contact Us
            </HashLink>
            <a target='_blank' rel='noreferrer' href='https://hydrogenconferenceng.com/' className="text-white bg-green-700 hover:bg-green-600 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl">
                HYDROGEN CONFERENCE
            </a>
        </>
    )
}

export default NavLinks;
