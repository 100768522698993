import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import Modal from 'react-modal';
import img1 from '../images/image1.jpeg';
import img2 from '../images/image2.jpeg';
import img3 from '../images/image3.jpeg';
import img4 from '../images/image4.jpeg';

const About = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const images = [img1, img2, img3, img4];

    const openModal = (image) => {
        setSelectedImage(image);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedImage(null);
    };

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id="about" className="bg-gray-100 py-12">
                <section className="container mx-auto px-6">
                    {/* Page Header */}
                    <div className="text-center mb-12" data-aos="fade-up">
                        <br/><br/>
                        <h2 className="text-4xl font-bold text-green-700 uppercase">About Us</h2>
                        <div className="flex justify-center mt-2">
                            <div className="w-24 border-b-4 border-green-700"></div>
                        </div>
                    </div>

                    {/* Mission and Vision Section */}
                    <div className="grid lg:grid-cols-2 gap-8 mb-8">
                        <div className="bg-white rounded-lg shadow-lg p-6" data-aos="fade-right">
                            <h3 className="text-3xl font-bold text-green-700">Mission</h3>
                            <p className="mt-4 text-lg text-gray-700">
                                FOSSREA is a non-profit organization dedicated to promoting sustainable social responsibility in emerging Africa, with a focus on Nigeria. Our mission is to provide a platform for Nigerians in various fields, both locally and in the diaspora, to give back to their country and contribute to its development.
                            </p>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6" data-aos="fade-left">
                            <h3 className="text-3xl font-bold text-green-700">Vision</h3>
                            <p className="mt-4 text-lg text-gray-700">
                                Our vision is to see a Nigeria where citizens are empowered to take ownership of their country's development, and where the public and private sectors work together to create a prosperous and sustainable future for all.
                            </p>
                        </div>
                    </div>

                    {/* Objectives and Values Section */}
                    <div className="grid lg:grid-cols-2 gap-8 mb-8">
                        <div className="bg-white rounded-lg shadow-lg p-6" data-aos="fade-right">
                            <h3 className="text-3xl font-bold text-green-700">Objectives</h3>
                            <ul className="list-disc list-inside mt-4 text-lg text-gray-700">
                                <li>To provide a platform for Nigerians to give back to their country and contribute to its development</li>
                                <li>To promote sustainable social responsibility in emerging Africa, with a focus on Nigeria</li>
                                <li>To advocate for efficient use of resources and fight against corruption</li>
                                <li>To build capacity and support the public sector in achieving its development goals</li>
                                <li>To foster collaboration and cooperation between the public and private sectors</li>
                            </ul>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6" data-aos="fade-left">
                            <h3 className="text-3xl font-bold text-green-700">Values</h3>
                            <ul className="list-disc list-inside mt-4 text-lg text-gray-700">
                                <li>Patriotism: We are committed to serving Nigeria and promoting its development</li>
                                <li>Integrity: We operate with transparency and accountability</li>
                                <li>Excellence: We strive for excellence in all our endeavors</li>
                                <li>Collaboration: We believe in the power of partnership and cooperation</li>
                                <li>Sustainability: We are dedicated to promoting sustainable development practices</li>
                            </ul>
                        </div>
                    </div>

                    {/* Activities Section */}
                    <div className="bg-white rounded-lg shadow-lg p-6 mb-8" data-aos="fade-right">
                        <h3 className="text-3xl font-bold text-green-700">Activities</h3>
                        <ul className="list-disc list-inside mt-4 text-lg text-gray-700">
                            <li>Capacity building and training programs</li>
                            <li>Advocacy and policy engagement</li>
                            <li>Community development projects</li>
                            <li>Research and development</li>
                            <li>Partnerships and collaboration with public and private sector organizations</li>
                        </ul>
                    </div>

                    {/* Membership and Governance Section */}
                    <div className="grid lg:grid-cols-2 gap-8 mb-8">
                        <div className="bg-white rounded-lg shadow-lg p-6" data-aos="fade-right">
                            <h3 className="text-3xl font-bold text-green-700">Membership</h3>
                            <p className="mt-4 text-lg text-gray-700">
                                FOSSREA is open to all Nigerians, both locally and in the diaspora, who share our vision and values. Members can contribute their time, expertise, and resources to support our activities and projects.
                            </p>
                        </div>

                        <div className="bg-white rounded-lg shadow-lg p-6" data-aos="fade-left">
                            <h3 className="text-3xl font-bold text-green-700">Governance</h3>
                            <p className="mt-4 text-lg text-gray-700">
                                FOSSREA is governed by a Board of Trustees, which includes patriotic Nigerians with diverse experiences. The Board is responsible for setting the organization's strategy and overseeing its activities.
                            </p>
                        </div>
                    </div>

                    {/* Partnerships Section */}
                    <div className="bg-white rounded-lg shadow-lg p-6 mb-8" data-aos="fade-left">
                        <h3 className="text-3xl font-bold text-green-700">Partnerships</h3>
                        <p className="mt-4 text-lg text-gray-700">
                            FOSSREA partners with government agencies, private sector organizations, and civil society groups to promote sustainable social responsibility and collaborative development.
                        </p>
                    </div>

                    {/* Our Impact Section with Image Grid */}
                    <div className="bg-white rounded-lg shadow-lg p-6 mb-8" data-aos="fade-up">
                        <h3 className="text-3xl font-bold text-green-700 text-center mb-4">Our Archives</h3>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {images.map((image, index) => (
                                <div key={index} className="cursor-pointer" onClick={() => openModal(image)}>
                                    <img src={image} alt={`Impact ${index + 1}`} className="w-full h-48 object-cover rounded-lg shadow-md" />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Modal for Full Image Display */}
                    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Modal" className="Modal" overlayClassName="Overlay">
                        <button onClick={closeModal} className="absolute top-0 right-0 m-4 text-white text-lg font-bold">X</button>
                        {selectedImage && (
                            <div className="flex justify-center items-center h-full">
                                <img src={selectedImage} alt="Selected" className="max-w-full max-h-full rounded-lg shadow-lg"/>
                            </div>
                        )}
                    </Modal>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default About;
